html body {
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 14px !important;
  background-color: #ecebdf;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Red Hat Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.MuiDataGrid-columnHeaderTitle {
  color: #3f4254 !important;
  font-weight: 900 !important;
  font-size: 15px !important;
  font-family: "DM Sans", sans-serif;
  text-transform: uppercase !important;
}

.MuiDataGrid-columnHeaderTitle:hover {
  color: var(--bs-gray-700) !important;
}

.MuiDataGrid-cellContent,
.MuiDataGrid-cell--withRenderer,
.table-loader-label {
  color: #3f4254 !important;
  font-family: Inter, Helvetica, "sans-serif";
  font-size: 0.9rem !important;
  letter-spacing: 1.1px;
  font-weight: 500 !important;
}

input[type="time"] {
  display: inline-block;
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.rmsc .dropdown-container {
  position: relative !important;
  outline: 0 !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}

input[type="date"] {
  display: inline-block;
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

#kt_app_header {
  background-color: #ecebdf !important;
  border-bottom: 1px solid #21212126;
}

.flag-dropdown {
  background-color: #f9f9f9 !important;
  border: none !important;
}

.flag-dropdown:hover {
  background-color: #f9f9f9 !important;
}

/* Added By Nilesh */
.bg-image-shape-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: #eb7626;
}
.login-bg-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  z-index: 1;
}
.login-shape {
  position: absolute;
  z-index: 2;
  width: 11.75rem;
  top: 0;
  left: 2.55rem;
}
.login-form-box {
  background: #212121;
  border-radius: 1rem;
  color: #ecebdf;
  padding: 0.8rem 3.5rem;

}
.logo-box {
  max-width: 240px;
  margin: 0 auto;
  width: 100%;
}
.logo-box figure {
  margin: 0;
}
.logo-box img {
  width: 100%;
  height: auto;
}
.login-form-box h1 {
  font-size: 2.25rem;
  color: #ecebdf;
}
div .form-control {
  background: #ecebdf;
  box-shadow: none;
  outline: none;
}

.app-sidebar-logo {
  background: #212121;
  text-align: center;
  border-color: transparent !important;
}
.app-sidebar-logo a {
  display: table;
  margin: 0 auto;
}
.app-page,
.app-page .app-footer {
  background: #ecebdf;
}
.page-heading {
  font-weight: 900;
  color: #212121;
}
.app-wrapper .app-sidebar {
  background: #212121 !important;
}
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link
  .menu-icon
  i,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link
  .menu-title {
  color: #fff;
  transition: 0.35s all;
}
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active {
  background-color: #eb7626;
  color: #fff;
}
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active
  .menu-icon
  i,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item
  .menu-link.active
  .menu-title {
  color: #fff;
}
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-title,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item:not(.here)
  .menu-link:hover:not(.disabled):not(.active):not(.here)
  .menu-icon
  i {
  color: #eb7626;
}
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-icon
  i,
[data-kt-app-layout="light-sidebar"]
  div
  .app-sidebar
  .menu
  .menu-item.show
  > .menu-link
  .menu-title {
  color: #eb7626 !important;
}
.app-sidebar-toggle {
  height: 24px;
  width: 54px;
  padding-left: 30px;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.app-sidebar-toggle svg {
  width: 100%;
  height: auto;
}
.app-sidebar-toggle-ico svg,
.app-sidebar-toggle svg {
  fill: #212121;
}

.app-sidebar-logo .app-sidebar-toggle {
  background: transparent !important;
  box-shadow: none !important;
}
[data-kt-app-sidebar-fixed="true"] .app-wrapper {
  transition: 0.25s all;
}
.fmenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.fmenu ul li a {
  color: #212121;
  text-decoration: none;
}
.fmenu ul li a:hover {
  color: #eb7626;
}
.fmenu ul li {
  margin: 5px 30px 5px 0;
  line-height: 1;
}
.fmenu ul li:last-child {
  margin-right: 0;
}

.top-search {
  max-width: 300px;
  width: 100%;
  position: relative;
}
.top-search i {
  position: absolute;
  left: 1rem;
  bottom: 0.9rem;
  color: #9a9a9a;
}
.top-search i .path1:before {
  opacity: 1;
}
.top-search .form-control.form-control-solid {
  padding-left: 3.3rem;
  background: #ecebdf;
  border-color: #ecebdf;
  color: #212121;
}
.card .MuiDataGrid-main .MuiDataGrid-row .MuiDataGrid-cell {
  font-size: 13px !important;
  border-color: transparent;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader:focus {
  outline: none;
}
.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader
  .MuiDataGrid-columnHeaderTitle {
  color: #212121 !important;
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 1.1rem !important;
  font-family: "DM Sans", sans-serif;
  white-space: pre-wrap;
  line-height: normal;
}
.MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner
  .MuiDataGrid-columnHeader {
  border-color: transparent;
  padding: 0 1.5rem;
}
.card .MuiDataGrid-columnHeaders,
.card .MuiDataGrid-root .MuiDataGrid-footerContainer {
  border-color: transparent !important;
}
.card .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row {
  background: #ecebdf80;
}
.card .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:nth-child(2n + 2) {
  background: #fff;
}
.MuiTablePagination-root .MuiTablePagination-selectLabel,
.MuiTablePagination-root .MuiInputBase-root,
.MuiTablePagination-root .MuiTablePagination-displayedRows {
  color: #212121;
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
}
.MuiTablePagination-root .MuiTablePagination-actions .MuiButtonBase-root {
  background: #ecebdf;
  color: #212121;
  margin-right: 10px;
}
.MuiTablePagination-root .MuiTablePagination-actions .MuiButtonBase-root:hover {
  background: #eb7626;
  color: #fff;
}
.MuiTablePagination-root
  .MuiTablePagination-actions
  .MuiButtonBase-root:last-child {
  margin-right: 0;
  align-items: center;
}

.MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
  color: #212121;
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
}
.app-content .form-select.form-select-solid,
.app-content .form-select.form-select-solid,
.app-content .form-control.form-control-solid {
  background-color: #ecebdf;
  border-color: #ecebdf;
  color: #212121;
}
.notification-ico {
  position: relative;
  margin: 0 1rem 0 0.5rem;
  cursor: pointer;
}
.notification-ico figure {
  height: 20px;
  width: 20px;
  margin: 0;
}
.notification-ico svg {
  width: 100%;
  height: auto;
  fill: #212121;
}
.notification-ico .count {
  position: absolute;
  top: -7px;
  right: -2px;
  height: 15px;
  width: 15px;
  background: #eb7627;
  color: #fff;
  font-weight: 600;
  font-size: 0.8rem;
  border-radius: 10px;
  text-align: center;
  line-height: 15px;
}
.app-container .card {
  position: relative;
}
.top-title {
  position: absolute;
  left: 1.5rem;
  right: 14rem;
  z-index: 5;
  top: 2.3rem;
}
.top-title h5 {
  margin: 0;
}
.filter-list ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.filter-btn { height: 40px; width: 40px; min-width: 40px; }
.filter-list ul li {
  margin: 0.5rem;
  background: #8081844D;
  padding: 0.7rem 2.6rem 0.6rem 1.3rem;
  border-radius: 100px;
  position: relative;
}
.filter-list ul li:first-child { margin-left: 0; }
.filter-close-ico {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0.7rem;
  height: 1.4rem;
  width: 1.4rem;
  background: #ECEBDF;
  border: 1px solid #fff;
  border-radius: 50px;
  font-weight: bold;
  padding: 0;
  line-height: 1.2rem;
  text-align: center;
  cursor: pointer;
  display: block;
  transition: 0.35s all;
  color: #212121;
}
.filter-close-ico:hover { background: #EB7626; border-color: #EB7626; color: #fff; }
.dashboard-card-box.card {
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 30px 0px #2121210D;
    border-radius: 8px;
    z-index: 1;
    background: #fff;
    height: 100%;
    min-height: 260px;
}
.dashboard-card-box.card:hover { background: #EB7626; }

.dashboard-card-box .card-body { transition: 0.35s all; display: flex; flex-direction: column; }

.dashboard-card-box h5 {
  color: #212121;
  font-size: 1.5rem;
  font-weight: 500;
  transition: 0.35s all;
}
.dashboard-card-box h2{ transition: 0.35s all;} 
.dashboard-card-box:hover h5, .dashboard-card-box:hover h2 { color: #fff; }
.dashboard-card-box figure {
  width: 100px;
  min-width: 100px;
  margin: 0 0 0 auto;
}
.dashboard-card-box figure svg { fill: #EB7626; width: 100%; transition: 0.35s all; }
.dashboard-card-box h3 {
  color: #212121;
  font-size: 5rem;
  font-weight: 700;
  margin: 0;
  font-family: "DM Sans", sans-serif;
  transition: 0.35s all;
}
.dashboard-card-box:hover h3 { color: #fff; }
.dashboard-card-box:hover figure svg { fill: #fff; }
.rate-time { color: #212121; transition: 0.35s all; white-space: nowrap; }
.dashboard-card-box:hover .rate-time { color: #ffffff; }
.btn.rate-time {
  background: rgba(0,0,0,0.1);
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.35s all;
}
.btn.rate-time:after {
  content: "";
  height: 0;
  width: 0;
  border-top: 5px solid rgba(0,0,0,0.5);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin: 0 -0.4rem 0 0.6rem;
  transition: 0.35s all;
}
.dashboard-card-box:hover .btn.rate-time { background: rgba(255,255,255,0.2); }
.dashboard-card-box:hover .btn.rate-time:after { border-top-color: rgba(255,255,255,0.7); }


.dashboard-card-bottom {
  margin-top: auto;
}
.card-bubble {
  position: absolute;
  height: 16rem;
  width: 16rem;
  background: rgba(0,0,0,0.04);
  border-radius: 50%;
  z-index: -1;
}
.dashboard-card-01 .card-bubble {
  bottom: -17%;
  right: -6%;
}
.dashboard-card-02 .card-bubble {
  top: -42%;
  right: -13%;
}
.dashboard-card-03 .card-bubble {
  top: 13%;
  left: -21%;
}
.dashboard-card-04 .card-bubble { 
  bottom: -24%;
  right: 13%; }
.dashboard-card-05 .card-bubble { 
  top: -40%;
  left: 50%;
  transform: translateX(-50%); 
}
.dashboard-card-06 .card-bubble {
    top: 10%;
    right: -17%;
}

@media only screen and (max-width: 1399px) {
  .dashboard-card-box figure { width: 70px; min-width: 70px; }
  .dashboard-card-box h3 { font-size: 4rem; }
  .dashboard-card-box h5 { font-size: 1.4rem; }
  .dashboard-card-bottom { padding-top: 5rem; }
  .card-bubble { height: 12rem; width: 12rem; }
  
}
@media only screen and (max-width: 1199px) {
  .login-form-box {
    padding: 4.5rem;
  }
  .dashboard-card-box h3 { font-size: 3.2rem; }
  .dashboard-card-box figure { width: 60px; min-width: 60px; }
  .dashboard-card-bottom { padding-top: 3rem; }
}
@media only screen and (max-width: 767px) {
  .login-form-box {
    padding: 4rem;
  }
  .top-title {
    position: static;
    margin-bottom: 1.5rem;
  }
  .dashboard-card-box figure { width: 70px; min-width: 70px; }
}
@media only screen and (max-width: 575px) {
  .login-form-box {
    padding: 3rem;
  }
  .top-search {
    max-width: 100%;
  }
}
